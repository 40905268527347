import * as React from 'react';
import { Link, graphql } from 'gatsby';
import { getImage } from 'gatsby-plugin-image'

import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from 'gatsby-background-image'

import Layout from '../components/layout';

const CareerPage = ({ data, pageContext }) => {
  
  return (
    <Layout title="Karriere" heroTitle="Kluge Köpfe gesucht" heroSubtitle="" imageData={data.heroImage.childImageSharp.gatsbyImageData} pageContext={pageContext}>
      <section className="section">
        <div className="container">
        
          <h1 className="title">Unsere offenen Positionen</h1>
          
          {
            data.allMdx.nodes.map((node) => (
              <div className="columns">
                <div className="column is-one-third is-hidden-mobile">
                  <Link to={`/${node.slug}`}>
                    <BackgroundImage
                      Tag="div"
                      style={{height: "100%", filter: "saturate(0.5)"}}
                      {...convertToBgImage(getImage(node.frontmatter.hero_image))}
                    >
                    </BackgroundImage>
                  </Link>
                </div>
                <div className="column">
                  <Link className="subtitle has-text-primary is-size-5" to={`/${node.slug}`}>
                    <h2 className="mb-1">
                      {node.frontmatter.title}
                    </h2>
                  </Link>
                  <p className="has-text-grey">{node.frontmatter.date}</p>
                  <p className="is-hidden-tablet">
                    <Link to={`/${node.slug}`}>
                      <BackgroundImage
                        Tag="div"
                        className="is-flex is-flex-direction-column is-justify-content-space-between"
                        style={{padding: "1.5rem 1.5rem 1.5rem 1.5rem", height: "8rem", filter: "saturate(0.5)"}}
                        {...convertToBgImage(getImage(node.frontmatter.hero_image))}
                      >
                      </BackgroundImage>
                    </Link>
                  </p>
                  <p className="mb-0">{node.excerpt}</p>
                </div>
              </div>
            ))
          }
          
        </div>
      </section>
    </Layout>
  )
}

// hero_image_alt: Teamwork makes the dream work.
// hero_image_credit_text: Dylan Gillis
// hero_image_credit_link: https://unsplash.com/photos/KdeqA3aTnBY

export const query = graphql`query CareerPage {
  heroImage: file(relativePath: {eq: "karriere.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  allMdx(filter: {frontmatter: {templateKey: {eq: "karriere-page"}}}) {
    nodes {
      frontmatter {
        title
        date(formatString: "DD. MMMM YYYY", locale: "de-DE")
        tags
        authors {
          frontmatter {
            partnerName
          }
        }
        hero_image_alt
        hero_image_credit_link
        hero_image_credit_text
        hero_image {
          childImageSharp {
            gatsbyImageData(
              aspectRatio: 3,
              width: 600,
              transformOptions: {
                cropFocus: CENTER
              }
            )
          }
        }
      }
      id
      slug
      excerpt(pruneLength: 200)
    }
  }
}
`

export default CareerPage;
